import {FormControl, InputLabel, Select, Grid, 
    MenuItem, LinearProgress, TextField,
    Typography,
    Button,
    IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import NoImage from '../../Layout/Images/NoImage.png'
import qs from "qs";
import {apiFDataHeader, ErrorGeneral, llamaApiCargaToken} from "../../funciones/ClienteAxios";
import {useNavigate} from "react-router-dom";
import FormDialog from "../../Layout/FormDialog";
import FormDialogInfo from "../../Layout/FormDialogInfo";
import {successToast, warningToast} from "../../Generales/Tools";
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';


const EditAgrup = ({modalAbierto, setModalAbierto, registro, listStatus, actualizar}) => {

    const source = axios.CancelToken.source();
    const usuId = localStorage.getItem('UsuId');
    const history = useNavigate();
    const Dispvo = localStorage.getItem('Dispvo');
    const auxDispV = Dispvo.length >= 12 ? Dispvo : '451224452121'
    const [agrupacion, setAgrupacion] = useState([]);
    const [espera, setEspera] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
                    
    const [cargando, setCargando] = useState(true)     
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);
    const [nuevoInterprete, setNuevoInterprete] = useState({Status:"",img: '', name: '', Contacto: '', tel: '', email: ''})
    const [nameError, setNameError] = useState('')
    const [representError, setRepresentError] = useState('')
    const [telError, setTelError] = useState('')
    const [emailError, setEmailError] = useState('') 
    const {Status}=nuevoInterprete
    useEffect(()=>{
        
        setNuevoInterprete({
            img: registro.Img,
            name: registro.Nom,
            Contacto: registro.Contacto,
            tel: registro.Tel,
            email: registro.Email,
            Status:registro.StId,
            ...registro
        })
    },[])

    function editarInterprete() {
        setCargando(true)
        const data = qs.stringify({               
            usuario:usuId,
            agrupacion:registro.Id,
            status:Status,
            idDispositivo: auxDispV
        });

        const url = "agrupacion/status/edit";

        function respuesta(response) {
            if (response.respuesta === 1) {
                successToast('El interprete ' + nuevoInterprete.name + ' se edito correctamente.')
                setModalAbierto(false)
                setIsUpdate(false)
                
            } else {
                warningToast('Ocurrio un error.')
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history,source);
    }
      
    const handleInterprete = (event) => {
        setNameError('')
        setRepresentError('')
        setTelError('')
        setEmailError('')
        //  setBtnDeshabilitado(false)
        setNuevoInterprete({...nuevoInterprete, [event.target.name]: event.target.value});
    } 

    const onChange=(e)=>{    
        setNuevoInterprete({...nuevoInterprete,[e.target.name] : e.target.value})      
    }

    const handleNuevoInterprete = (event) => {
        event.preventDefault();
  
        setCargando(true)
        
        editarInterprete()               
    }

    const acortaRuta=(ruta)=>{
        
        const contador =ruta.lastIndexOf("/")
        let respuesta=""
        if (contador!==-1) {
            if ( ( contador + 1 ) === ruta.length) {
                const aux1 = ruta.substring(0, ruta.length-1)
                const contador2 =aux1.lastIndexOf("/")
                respuesta=ruta.substring(contador2+1, ruta.length-1)
            }
            else{
                respuesta=ruta.substring(contador+1, ruta.length)
            }            
        }
        return respuesta
    }
 
    const selectStatus= <FormControl sx={{   width: "10rem" ,marginRight:1+'rem'}} size="small">
        <InputLabel id="demo-select-small">Estatus</InputLabel>
        <Select
        labelId="select-tipo-inp" id="select-Tipo" name="Status"
        value={Status} label="Estatus" onChange={onChange}
        >      
        {listStatus.map((tipo)=><MenuItem value={tipo.Id}>{tipo.Nom}</MenuItem>)}
        </Select>
    </FormControl>  

    return (                         
    <FormDialog tamanio={  'md' }
        titulo={ "Agrupación " + registro.Nom  }
        modalAbierto={modalAbierto} setModalAbierto={setModalAbierto}
        btnDeshabilitado={btnDeshabilitado} cargando={espera}
        guardarDatos={handleNuevoInterprete}  setCargando={setEspera}
        bgTitle={false}
    >
        <form noValidate autoComplete={'off'}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
           
            <Grid item xs={4} textAlign={'center'}>
                <img src={nuevoInterprete.img === '' ? NoImage : nuevoInterprete.img}
                    width={200} height={200} style={{borderRadius:.5+'rem'}} />
            </Grid>  
            <Grid item xs={8}>
                <Grid container spacing={2}>                                
                    <Grid item xs={6}>
                        {selectStatus}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField //onChange={handleInterprete} 
                            id="EdoNom" name="EdoNom"
                            label="Estado" disabled 
                            variant="standard" value={nuevoInterprete.EdoNom}
                            error={representError !== '' ? true : false}
                            helperText={representError}
                            type={'text'}  fullWidth size="small"
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField //onChange={handleInterprete} 
                            id="MuniNom" name="MuniNom"
                            label="Municipio" disabled 
                            variant="standard" value={nuevoInterprete.MuniNom}
                            error={representError !== '' ? true : false}
                            helperText={representError}
                            type={'text'}  fullWidth size="small"
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField onChange={handleInterprete} id="represent" name="Alias"
                            label="Alias" disabled 
                            variant="standard" value={nuevoInterprete.Alias}
                            error={representError !== '' ? true : false}
                            helperText={representError}
                            type={'text'}  fullWidth size="small"
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="name" name="name" label="Nombre" variant="standard"
                            value={nuevoInterprete.name} size="small"
                            error={nameError !== '' ? true : false} helperText={nameError}
                            disabled fullWidth
                            onChange={handleInterprete}
                            type={'text'}/>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField onChange={handleInterprete} id="represent" name="Alias"
                            label="Tipo" disabled
                            variant="standard" value={nuevoInterprete.TpNom}
                            error={representError !== '' ? true : false}
                            helperText={representError}
                            type={'text'}  fullWidth size="small"
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField onChange={handleInterprete} id="represent" name="Contacto"
                            label="Contacto" disabled
                            variant="standard" value={nuevoInterprete.Contacto}
                            error={representError !== '' ? true : false}
                            helperText={representError}
                            type={'text'}  fullWidth size="small"
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField onChange={handleInterprete} id="tel" name="tel" label="Teléfono"
                            value={nuevoInterprete.tel} disabled   size="small"
                            error={telError !== '' ? true : false} helperText={telError}
                            variant="standard" type={'tel'} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField onChange={handleInterprete} id="email" name="email" label="Email"
                            value={nuevoInterprete.email} disabled fullWidth
                            error={emailError !== '' ? true : false} helperText={emailError}
                            variant="standard" type={'email'} size="small"
                            />
                    </Grid>
                    {nuevoInterprete.Facebook && nuevoInterprete.Facebook!==""?
                        <Grid item xs={6}   direction="row" container>
                            <a href={nuevoInterprete.Facebook} target="_blank">
                                <FacebookIcon size="small" color=""/>
                            </a>
                            <Typography variant="body1" sx={{marginLeft:".5rem"}} >
                                {acortaRuta(nuevoInterprete.Facebook)} 
                            </Typography>
                        {/* <TextField onChange={handleInterprete} id="Facebook" name="tel" label="Facebook"
                            value={nuevoInterprete.Facebook} disabled fullWidth
                            error={telError !== '' ? true : false} helperText={telError}
                            variant="standard" type={'text'} /> */}
                        </Grid>
                    :null}
                    
                    {nuevoInterprete.Instagram && nuevoInterprete.Instagram!==""?
                        <Grid item xs={6} container direction="row" >
                            <a href={nuevoInterprete.Instagram} target="_blank">
                                <InstagramIcon size="small"/>
                            </a>
                            <Typography variant="body1"  
                                sx={{marginLeft:.5+'rem'}}>
                                {acortaRuta(nuevoInterprete.Instagram)} 
                            </Typography>                        
                        </Grid>
                    :null}
                    
                    {nuevoInterprete.Twitter && nuevoInterprete.Twitter!==""?
                        <Grid item xs={6} container direction="row">
                            <a href={nuevoInterprete.Twitter} target="_blank">
                                <img src="X_icon.svg" style={{width:"20px"}}/> 
                            </a>
                            
                            <Typography variant="body1"  
                                sx={{marginLeft:.5+'rem'}}>
                                {acortaRuta(nuevoInterprete.Twitter)} 
                            </Typography>                           
                        </Grid>
                    :null}
                    
                    {nuevoInterprete.Youtube && nuevoInterprete.Youtube!==""?
                        <Grid item xs={12} container direction="row" >
                            <a href={nuevoInterprete.Youtube} target="_blank">
                            <YouTubeIcon size="small"/>
                            </a>
                            <Typography variant="body1"  
                                sx={{marginLeft:.5+'rem'}}>
                                {acortaRuta(nuevoInterprete.Youtube)} 
                            </Typography>                           
                        </Grid>
                    :null}
                   
                </Grid>
            </Grid>
        </Grid>
        </form>
    </FormDialog>
    );
    }
;
export default EditAgrup;
