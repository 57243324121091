import React from 'react';
import {Avatar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, Tooltip} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

const TableInterprete = ({abrirModalNuevo, interpretes, handleEditar, abrirModalImagen}) => {

    const Celda =({titulo, indice})=>{
        return(
        <TableCell align="center" sx={{ padding: '0.2rem', color: (theme) => theme.palette.secondary.contrastText}} 
            key={indice}>
            {titulo}
        </TableCell>
        )
    }

    const CeldaCampo=({campo,leyenda,descr,alineado})=>{
        return(
        <TableCell component="th" scope="row" align={alineado??'center'}
            sx={{padding: '0.2rem', color: campo === '' ? 'tomato' : null}}>
                {campo === '' ? leyenda :campo}
        </TableCell>
        )
    }

    return (
    <TableContainer component={Paper} sx={{maxHeight: '80vh'}}>
        <Table sx={{width: '100%'}} aria-label="interpretes table">
            <TableHead sx={{
                backgroundColor: (theme) => theme.palette.secondary.light,
                padding: '0.2rem'
            }}>
                <TableRow>
                    <TableCell align="center" sx={{
                        padding: '0.2rem',
                        color: (theme) => theme.palette.secondary.contrastText
                    }}>{/*  <Tooltip title={'Nuevo Interprete'} arrow placement="top">
                        <IconButton onClick={abrirModalNuevo} color={'inherit'} size={'small'}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Tooltip> */}
                    </TableCell>
                    <Celda titulo={"Estado"} indice={1} />                        
                    <Celda titulo={"Municipio"} indice={2} />
                    <Celda titulo={"Tipo"} indice={3} />   
                    <Celda titulo={"Género"} indice={3} />   
                    <Celda titulo={"Nombre"} indice={4} />
                    <Celda titulo={"Contacto"} indice={5} />                                                                        
                    <Celda titulo={"Teléfono"} indice={6} />
                    <Celda titulo={"Email"} indice={7} />                                                                                                                        
                </TableRow>
            </TableHead>
            <TableBody>
                {interpretes.length === 0 ? 
                    <TableRow hover sx={{
                        '&:hover .MuiTableCell-root, &:hover .MuiTableCell-root span.material-icons-outlined': {
                            backgroundColor: (theme) => theme.palette.secondary.light,
                            color: (theme) => theme.palette.secondary.contrastText,
                        }, '&:last-child td, &:last-child th': {border: 0},
                    }}>
                        <TableCell component="th" scope="row" sx={{padding: '0.2rem', color: 'red'}}
                            colSpan={"100%"} align={'center'}>
                            <h4>No existen datos </h4>
                        </TableCell>
                    </TableRow>

                : interpretes.map((row, index) => (<TableRow hover key={index} sx={{
                    '&:hover .MuiTableCell-root': {
                        backgroundColor: (theme) => theme.palette.secondary.light,
                        color: (theme) => theme.palette.secondary.contrastText,
                    }, '&:last-child td, &:last-child th': {border: 0},
                }}>
                    <TableCell component="th" scope="row" align={'center'}
                            sx={{padding: '0.2rem'}}>
                            <Tooltip title={'Ver Agrupación'}
                                arrow placement="top">
                                <IconButton onClick={() => handleEditar(row.Id)} size={'small'} color={'inherit'}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip> 
                            <Tooltip title={'Ver Imagen'} arrow placement="top">
                                <IconButton onClick={() => abrirModalImagen(row.Id)} size={'small'}>
                                    <Avatar alt={row.Alias} src={row.Img} sx={{ width: 32, height: 32 }}/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <CeldaCampo campo={row.EdoNom} leyenda={'Sin Estado'}  />
                        <CeldaCampo campo={row.MuniNom} leyenda={'Sin Municipio'}  />
                        <CeldaCampo campo={row.TpNom} leyenda={'Sin Tipo'}  />
                        <CeldaCampo campo={row.GenNom} leyenda={'Sin Tipo'}  />
                        <CeldaCampo campo={row.Nom} leyenda={'Sin Nombre'}  />
                        <CeldaCampo campo={row.Contacto} leyenda={'Sin Contacto'}  />
                        <CeldaCampo campo={row.Tel} leyenda={'Sin Telefono'}  />                            
                        <CeldaCampo campo={row.Email} leyenda={'Sin Email'}  />                                                        
                    </TableRow>))}
            </TableBody>
        </Table>
    </TableContainer>
    );
};

export default TableInterprete;
